<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
    <sz-input [(ngModel)]="deviceFilter" />
    <cdk-virtual-scroll-viewport
        itemSize="48"
        class="mt-2 h-[200px] max-h-[300px] min-h-[100px]"
    >
        @if (multiple) {
            <mat-selection-list
                (selectionChange)="handleSelectionChange($event.options)"
            >
                <mat-list-option
                    *ngFor="let item of devices | searchDevices: deviceFilter"
                    class="device-option"
                    [checkboxPosition]="'before'"
                    [value]="item.id"
                    [selected]="item.selected"
                >
                    <div
                        class="flex h-10 flex-row items-center justify-between gap-2 truncate px-2"
                    >
                        {{ item.identifier }}
                        @if (pinnedDevices.includes(item.id)) {
                            <mat-icon
                                class="!h-5 !w-5 text-[goldenrod]"
                                svgIcon="pin"
                            />
                        }
                    </div>
                </mat-list-option>
            </mat-selection-list>
        } @else {
            <mat-radio-group
                class="flex flex-col"
                [value]="selectedDeviceIds[0]"
            >
                <div
                    class="flex flex-row w-full items-center justify-between p-3"
                    *ngFor="let item of devices | searchDevices: deviceFilter"
                >
                    <mat-radio-button
                        class="device-option"
                        color="primary"
                        [value]="item.id"
                        (change)="selectedDeviceIds = [$event.value]"
                    >
                        {{ item.identifier }}
                    </mat-radio-button>
                    @if (pinnedDevices.includes(item.id)) {
                        <mat-icon
                            class="!h-5 !w-5 text-[goldenrod] mr-1"
                            svgIcon="pin"
                        />
                    }
                </div>
            </mat-radio-group>
        }
    </cdk-virtual-scroll-viewport>
    <sz-dialog-button-bar
        [primaryDisabled]="selectedDeviceIds.length === 0"
        (primaryClick)="dialogRef.close(selectedDeviceIds)"
        (secondaryClick)="dialogRef.close()"
    />
</div>

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/cached-api';
@Component({
    templateUrl: './select-devices-dialog.component.html',
})
export class SelectDevicesDialog implements OnInit {
    public multiple = true;
    public deviceFilter = '';
    public selectedDeviceIds: number[];
    public pinnedDevices = [];
    public devices: {
        id: number;
        selected: boolean;
        identifier: string;
    }[] = [];

    @HostListener('document:keydown.enter', ['$event'])
    handleEnterKeyPress() {
        if (this.dialogRef) {
            this.dialogRef.close(this.selectedDeviceIds);
        }
    }

    constructor(
        public dialogRef: MatDialogRef<SelectDevicesDialog>,
        private api: ApiService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        this.multiple = data.multiple ?? true;
        this.selectedDeviceIds = data.selectedDeviceIds ?? [];
        this.devices = data.devices;
    }

    async ngOnInit() {
        await ensureLoaded([this.api.pins]);
        this.api.pins.listen({ type: 'device' }).subscribe((pins) => {
            this.pinnedDevices = pins
                .sort((a, b) => a.order - b.order)
                .map((p) => p.itemId);
            this.updateDeviceList();
        });
    }

    updateDeviceList() {
        const sortedDevices = Array.from(this.devices).sort((a, b) => {
            const aIsPinned = this.pinnedDevices.includes(a.id);
            const bIsPinned = this.pinnedDevices.includes(b.id);

            if (aIsPinned && bIsPinned) {
                // Sort pinned devices by their order.
                return (
                    this.pinnedDevices.indexOf(a.id) -
                    this.pinnedDevices.indexOf(b.id)
                );
            } else if (aIsPinned !== bIsPinned) {
                // Put pinned devices above non-pinned devices.
                return aIsPinned ? -1 : 1;
            } else {
                return a.identifier.localeCompare(b.identifier);
            }
        });

        this.devices = sortedDevices;
    }

    handleSelectionChange(options: MatListOption[]) {
        for (const option of options) {
            const id = option.value;
            if (option.selected) {
                if (!this.selectedDeviceIds.includes(id)) {
                    this.selectedDeviceIds.push(id);
                }
            } else {
                const index = this.selectedDeviceIds.indexOf(id);
                if (index >= 0) {
                    this.selectedDeviceIds.splice(index, 1);
                }
            }
        }
    }
}
